<template>
  <div>
    <Header callBack @click="goBack"> {{ name }} </Header>
    <div class="search" v-if="!$route.query.id">
      <input type="text" placeholder="搜索公告名称" v-model="keyword" />
      <img src="../../../static/image/sousuo.png" alt="" @click="searchList" />
    </div>
    <div class="select" v-if="name == '公告详情'">
      <!-- <div
        v-for="list in navList"
        :key="list.type"
        @click="ternary(list.type)"
        :class="add == list.type ? 'select_tow' : 'select_text'"
      >
        {{ list.name }}
      </div> -->
      <van-tabs @click="onClick">
        <van-tab v-for="list in navList" :title="list.name" :key="list.type">
        </van-tab>
      </van-tabs>
    </div>
    <div class="shadow"></div>
    <div class="def" v-if="!getNotice.length">
      <img src="../../../static/image/hezi2.png" alt="" />
      <p>更多内容</p>
    </div>
    <div v-for="item in getNotice" :key="item.key">
      <div class="gonggao" @click="announcement(item.id)">
        <!-- <img src="../../../static/image/gonggao.png" alt="" /> -->
        <div class="gonggao_box left">
          <div class="gonggao_text">
            {{ item.title }}
          </div>
          <div class="gonggao_item">{{ item.created_at }}</div>
        </div>
        <div class="right">
          <img :src="item.cover_image" alt="" />
        </div>
      </div>
    </div>

    <div class="kefu" v-if="showKeFu" @click="showQrCode = true">
      <img src="../../../static/image/kefu.png" alt="" />
      <span>联系客服 </span>
    </div>

    <div class="box" v-if="showQrCode">
      <div class="content">
        <div class="kefuQrCode" v-html="showKeFu"></div>
        <i class="close" @click="showQrCode = false"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      getNotice: [],
      showQrCode: false,
      add: 0,
      navList: [],
      showKeFu: null,
      keyword: "",
      key: 0,
      name: "公告详情",
      text: [
        {
          typeid: 0,
          name: "全部公告",
        },
        {
          typeid: 3,
          name: "平台公告",
        },
        {
          typeid: 1,
          name: "上新公告",
        },
        {
          typeid: 2,
          name: "舆情公告",
        },
      ],
    };
  },
  watch: {
    keyword(val) {
      if (!val) {
        this.name = "公告详情";
        this.keyword = "";
        this.ternary(this.navList[this.key].type);
      }
    },
  },
  methods: {
    goBack() {
      if (this.name == "搜索结果") {
        this.name = "公告详情";
        this.keyword = "";
        this.ternary(this.navList[this.key].type);
      } else {
        this.$router.back();
      }
    },
    searchList() {
      this.name = "搜索结果";
      this.ternary(this.navList[this.key].type);
    },
    onClick(e) {
      this.key = e;
      this.showKeFu = this.navList[this.key].content;
      this.ternary(this.navList[this.key].type);
    },
    // 公告详情
    announcement(e) {
      this.$router.push({ path: "/Banner", query: { id: e } });
    },
    // 根据typeid显示不同内容
    ternary(e) {
      this.add = e;
      let params = {
        pageindex: 1,
        pagesize: 100,
        type: e,
      };
      if (this.keyword) {
        params.keyword = this.keyword;
      }
      this.$api.getNotice(params).then((res) => {
        if (res.code == 0) {
          this.getNotice = res.data;
        }
      });
    },
  },
  mounted() {
    let params = {
      pageindex: 1,
      pagesize: 100,
      type: 0,
    };
    if (this.$route.query.id) {
      params.goodsId = this.$route.query.id;
      this.name = this.$route.query.name;
    }
    this.$api.getNotice(params).then((res) => {
      if (res.code == 0) {
        this.getNotice = res.data;
      }
    });

    this.$api.getNoticeTypeList().then((res) => {
      if (res.code == 0) {
        this.navList = res.data;
      }
    });
  },
};
</script>
<style lang="less" scoped>
/deep/.van-tabs {
  width: 100%;
}
/deep/.van-tab--active {
  font-weight: bold;
  font-size: 16px;
}
/deep/.van-tabs__line {
  display: none;
  // font-size: 20px;
}
#top {
  padding-left: 15px;
}
.all {
  margin-left: 20px;
}
.search {
  background: #f8f8f8;
  margin: 5px 17px;
  border-radius: 20px;
  display: flex;
  padding: 10px 15px;
  align-items: center;
  input {
    flex: 1;
    background: transparent;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
  img {
    width: 15px;
    height: 15px;
  }
}
.select {
  display: flex;
  justify-content: center;
  .select_text {
    flex: 1;
    line-height: 50px;
    height: 50px;
    color: #999999;
    font-size: 14px;
    text-align: center;
  }
  .select_tow {
    text-align: center;
    flex: 1;
    font-weight: 600;
    line-height: 50px;
    height: 50px;
    color: black;
    font-size: 14px;
  }
}
.shadow {
  width: 100%;
  height: 10px;
  background-color: #f8f8f8;
}
.update {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  line-height: 50px;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 6px;
  img {
    width: 6px;
    height: 10px;
    display: block;
  }
}
.gonggao {
  display: flex;
  margin-left: 16px;
  margin-right: 16px;
  padding: 15px 0;
  border-bottom: 1px solid #f8f8f8;
  align-items: center;
  .left {
    flex: 2;
    padding-right: 15px;
  }
  .right {
    flex: 1;
    img {
      width: 100%;
      height: 70px;
      border-radius: 5px;
      vertical-align: middle;
    }
  }
  .gonggao_text {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .gonggao_item {
    font-size: 12px;
    color: #999999;
    line-height: 42px;
    margin-bottom: 7px;
  }
}
.def {
  text-align: center;
  p {
    font-size: 14px;
    color: #999;
  }
  img {
    width: 35%;
    margin-right: -25px;
  }
}
.kefu {
  position: fixed;
  right: 0;
  bottom: 130px;
  background: #ffffff;
  box-shadow: -4px 3px 10px 1px rgba(56, 129, 204, 0.3);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7px 9px;
  img {
    width: 38px;
  }
  span {
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
  }
}
.box {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .kefuQrCode {
      max-width: 80%;
      margin: 0 auto;
    }
    .close {
      display: flex;
      margin-top: 20px;
      width: 28px;
      height: 28px;
      border: 1px solid #ffffff;
      border-radius: 50%;
      position: relative;
      transform: rotate(45deg);
      &::after {
        display: block;
        content: "";
        height: 80%;
        background: #ffffff;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 1px;
        transform: translate(-50%, -50%);
      }
      &::before {
        transform: translate(-50%, -50%);
        display: block;
        content: "";
        width: 80%;
        background: #ffffff;
        position: absolute;
        left: 50%;
        top: 50%;
        height: 1px;
      }
    }
  }
}
</style>
<style>
.kefuQrCode img {
  max-width: 100%;
}
</style>
